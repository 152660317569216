<template>
  <div v-editable="blok" class="grid" :class="innerComponent ? `grid--${innerComponent}` : undefined">
    <div class="grid__inner">
      <StoryblokComponent v-for="blok in blok.columns" :key="blok._uid" :blok="blok" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object })

const innerComponent = computed(() => props.blok.columns[0].component)
const backgroundColor = computed(() => props.blok.background?.value || 'transparent')
</script>

<style lang="scss">
.grid {
  background-color: v-bind(backgroundColor);

  &__inner {
    @include mq($from: tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  &--feature .grid__inner {
    @include container;
    padding: 2rem 0;
  }
}
</style>
